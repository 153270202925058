import htmx from "htmx.org";
import Alpine from "alpinejs";
import { TokenManager } from "./token-manager";
window.Alpine = Alpine;

const tokenManager = new TokenManager();
Alpine.data("tokenManager", (token = undefined) => {
  return {
    manager: tokenManager,
    async signin() {
      await tokenManager.signin();
    },
    async logout() {
      await tokenManager.logout();
    },
  };
});
Alpine.start();

htmx.on("htmx:confirm", (e) => {
  const accessToken = tokenManager.accessToken();
  if (!accessToken) {
    // Cancel Request
    //e.preventDefault();
    // Resume request after login
    // auth.then(() => e.detail.issueRequest());
    //tokenManager.signin();
  }
});

document.body.addEventListener("htmx:configRequest", (e) => {
  const accessToken = tokenManager.accessToken();
  const url = new URL(window.location.href);
  const code = url.searchParams.get('code');
  if (code) {
    e.detail.parameters['code']= code;
  }
  if (accessToken) {
    e.detail.headers["Authorization"] = "Bearer " + accessToken;
  }
});


document.body.addEventListener("accessTokenReceived", (e) => {
  const accessToken = e.detail.value;
  if (accessToken) {
    tokenManager.storeAccessToken(accessToken);
  }
});